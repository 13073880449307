
import {defineComponent} from 'vue';
import onboardingStore from '@/store/onboarding';
import {theme} from '@/vendor/theme';

export default defineComponent({
    setup() {
        const {onboardingProgress} = onboardingStore;

        return {
            onboardingProgress,
            bannerImage: `${theme}-login.png`,
        };
    },
    computed: {
        onboardingValue(): number {
            if (!this.$route.name) {
                return 0;
            }

            return this.onboardingProgress(this.$route.name.toString());
        },
    },
});
