import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "container-fluid d-flex flex-column min-vh-100" }
const _hoisted_2 = { class: "row flex-grow-1 align-items-stretch" }
const _hoisted_3 = { class: "col-5 d-flex flex-column align-items-center" }
const _hoisted_4 = { class: "row w-100 flex-grow-1 align-items-center justify-content-center" }
const _hoisted_5 = { class: "col-7" }
const _hoisted_6 = {
  key: 0,
  class: "col-7 mb-8"
}
const _hoisted_7 = { class: "progress" }
const _hoisted_8 = { class: "col-7 d-flex align-items-center justify-content-center bg-light overflow-hidden" }
const _hoisted_9 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_PortalTarget = _resolveComponent("PortalTarget")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_RouterView)
          ])
        ]),
        (_ctx.onboardingValue)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", {
                  class: "progress-bar",
                  style: _normalizeStyle(`width: ${_ctx.onboardingValue}%`)
                }, null, 4)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_PortalTarget, { name: "auth-banner" }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              class: "vh-100",
              src: require(`@/assets/images/auth/${_ctx.bannerImage}`)
            }, null, 8, _hoisted_9)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}